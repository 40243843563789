import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import styled, { keyframes } from "styled-components"
import TarotCoverImage from "../../images/tarot/A-COVER-80.jpg"
import { Box, Paragraph } from "../commonElements"
import { useSwipeable } from "react-swipeable"
import { ArrowCircleLeft, ArrowCircleRight } from "@styled-icons/fa-solid"
import seedrandom from "seedrandom"
import useEvent from "../hook/useEvent"
import { isDesktop } from "react-device-detect"
import { Swipe } from "@styled-icons/material-rounded"

const ArrowBox = styled(Box)`
  margin-top: 2rem;
  z-index: 1;
  height: fit-content;
  transform: translateY(200%) translateX(-50%);
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  @media screen and (max-width: 447px) {
    transform: translateY(260%) translateX(-50%);
  }
`

const ArrowLeft = styled(Box)`
  float: left;
  cursor: pointer;
  color: ${props => props.color};
  @media screen and (max-width: 447px) {
    padding-bottom: 10px;
  }
`

const ArrowRight = styled(Box)`
  float: right;
  cursor: pointer;
  color: ${props => props.color};
  @media screen and (max-width: 447px) {
    padding-bottom: 10px;
  }
`

const GuessBox = styled(Box)`
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  margin: 0rem 0rem auto 0rem;
`

const Container = styled.div`
  position: relative;
  width: 296px;
  margin: 1.5rem auto auto auto;
  perspective: 1000px;
  transform: scale(0.5);
`

const swipeCards = (swipeDirection, carouselRadius) => keyframes`
  from {
    transform: translateZ(${-carouselRadius}px) rotateY(${
  swipeDirection === "left" ? 360 : 0
}deg);
  }
  to {
    transform: translateZ(${-carouselRadius}px) rotateY(${
  swipeDirection === "left" ? 0 : 360
}deg);
  }
`

const Carousel = styled.div`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateZ(${props => -props.carouselRadius}px);
  transform-style: preserve-3d;
  animation-name: ${props =>
    swipeCards(props.swipeDirection, props.carouselRadius)};
  animation-duration: 20s;
  animation-play-state: ${props => (props.swipeOn ? "running" : "paused")};
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  ${props => (!props.shuffleOn ? props.carouselCSS : props.shuffleCSS)};
`

const CarouselFace = styled.div`
  // ${props => console.log("rerender")}
  background-image: ${_ => `url(${TarotCoverImage})`};
  position: absolute;
  width: 276px;
  height: 473px;
  top: 35px;
  background-size: cover;
  display: flex;
  &:hover {
    top: 20px;
  }
`

const GuessParagraph = styled(Paragraph)`
  overflow: ${props => props.overflow};
  text-overflow: ${props => props.textOverflow};
  white-space: ${props => props.whiteSpace};
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  margin-left: ${props => props.ml};
  margin-bottom: ${props => props.mb};
  text-align: center;
  margin-right: 0.5rem;
`

const CarouselFace2 = React.memo(({ number, itemPicked }) => {
  // console.log("enter rerender");
  return (
    <CarouselFace onClick={() => itemPicked(number)}>
      {/* {number} */}
    </CarouselFace>
  )
})

const TarotController = ({ shuffleClicked, cardPicked, cardNumber }) => {
  const toolColorsPersist = useSelector(state => state.general.colors.array)
  const [toolColors, setToolColors] = useState(["#3D3D3D", "#3D3D3D"])
  const [swipeOn, setSwipeOn] = useState(false)
  const [swipeDirection, setSwipeDirection] = useState(null)
  const [shuffleCSS, setShuffleCSS] = useState("")
  const [shuffleOn, setShuffleOn] = useState(false)
  const [cardArray, setCardArray] = useState([])
  // const typePersist = useSelector(state => state.tarot.type)
  const [carouselCSS, setCarouselCSS] = useState("")
  const [carouselRadius, setCarouselRadius] = useState(0)

  useEffect(() => {
    if (toolColorsPersist !== undefined) {
      setToolColors(toolColorsPersist)
    } else {
      setToolColors(["#59569D", "#F25292"])
    }
  }, [toolColorsPersist])

  useEffect(() => {
    setCardArray(createCardArray())
    createCarouselCSS()
  }, [])

  useEffect(() => {
    if (shuffleClicked !== 0) {
      handleShuffle()
    }
  }, [
    shuffleClicked,
    // , typePersist
  ])

  const getTotalCards = () => {
    // return typePersist === "Yes or No" ? 66 : 78
    return 78
  }

  const createCardArray = () => {
    const totalCards = getTotalCards()
    const randomNumber = getRandomNumber(totalCards)
    const cardArray = []
    for (let i = 0; i < totalCards; i++) {
      const tempCard = {
        number: randomNumber[i],
        // number: i + 1
      }
      cardArray.push(tempCard)
    }
    return cardArray
  }

  const getCarouselRadius = () => {
    var carousel = document.querySelector("#tarot-carousel")
    var cellCount = getTotalCards()
    var cellSize = carousel.offsetWidth
    var radius = Math.round(cellSize / 2 / Math.tan(Math.PI / cellCount))
    return radius
  }

  const getRandomNumber = end => {
    const start = 1
    const nums = Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx)
    const ranNums = []
    let nums_length = nums.length
    let nums_idx = 0
    while (nums_length--) {
      const mathRandom = seedrandom()
      nums_idx = Math.floor(mathRandom() * (nums_length + 1))
      ranNums.push(nums[nums_idx])
      nums.splice(nums_idx, 1)
    }
    return ranNums
  }

  const handleArrow = direction => {
    if (direction === "left" || direction === "right") {
      // to clear all the handleSwipedStop setTimeout event
      const highestId = window.setTimeout(() => {
        for (let i = highestId; i >= 0; i--) {
          window.clearInterval(i)
        }
      }, 0)
      setSwipeDirection(direction)
      setSwipeOn(true)
    }
  }

  const handleKeyDown = event => {
    let keyCode = event.keyCode
    if (keyCode === 37 || keyCode === 39) {
      if (event.target.matches("input")) {
        return
      }
      event.preventDefault()
      if (cardNumber !== 0) {
        // press left and right when card pop up to show previous and next card for checking purpose
        // if (keyCode === 37) {
        //   cardPicked(cardNumber - 1)
        // } else if (keyCode === 39) {
        //   cardPicked(cardNumber + 1)
        // }
      } else {
        if (keyCode === 37) {
          setSwipeDirection("left")
        } else if (keyCode === 39) {
          setSwipeDirection("right")
        }
        setSwipeOn(true)
      }
    }
  }

  const handleKeyUp = event => {
    let keyCode = event.keyCode
    if (keyCode === 37 || keyCode === 39) {
      event.preventDefault()
      handleSwipedStop()
    }
  }

  const handleSwiped = eventData => {
    let direction = eventData.dir.toLowerCase()
    if (direction === "left" || direction === "right") {
      setSwipeDirection(direction)
      setSwipeOn(true)
    } else {
      setSwipeOn(false)
    }
  }

  const handleSwipedStop = () => {
    const timeOutId = setTimeout(() => {
      setSwipeOn(false)
    }, 200)
    return () => {
      clearTimeout(timeOutId)
    }
  }

  const handlers = useSwipeable({
    onSwipeStart: handleSwiped,
    onSwiped: handleSwipedStop,
    preventDefaultTouchmoveEvent: true,
    trackMouse: isDesktop ? true : false,
  })

  useEvent("keydown", handleKeyDown)
  useEvent("keyup", handleKeyUp)

  const handleShuffle = () => {
    let radius = carouselRadius
    if (carouselRadius === 0) {
      radius = getCarouselRadius()
      setCarouselRadius(radius)
    }

    const totalCards = getTotalCards()
    let styles = "animation-name: none; "
    for (let i = 0; i < totalCards; i++) {
      styles += `
    & > div:nth-child(${i + 1}) {
      transform: rotateY(0deg) translateZ(${radius}px);
    }
    `
    }
    setSwipeOn(false)
    setSwipeDirection("left")
    setShuffleCSS(styles)
    setShuffleOn(true)
    handleShuffleBack()
  }

  const handleShuffleBack = () => {
    let radius = carouselRadius
    if (carouselRadius === 0) {
      radius = getCarouselRadius()
      setCarouselRadius(radius)
    }
    const totalCards = getTotalCards()
    let timeRun = 0
    let maxTimeRun = 100
    if (process.env.GATSBY_BUILD_CONTEXT === "development") {
      maxTimeRun = 5
    }
    var intervalId = setInterval(
      function () {
        if (timeRun <= maxTimeRun) {
          let styles = ""
          for (let i = 0; i < totalCards; i++) {
            let deg = (360 / totalCards) * i
            deg = (deg / maxTimeRun) * timeRun
            styles += `
    & > div:nth-child(${i + 1}) {
      transform: rotateY(${deg}deg) translateZ(${radius}px);
    }
    `
          }
          setShuffleCSS(styles)
          timeRun = timeRun + 1
        } else {
          clearInterval(intervalId)
          setShuffleCSS("")
          setShuffleOn(false)
          setSwipeOn(false)
          setSwipeDirection("left")
        }
      },
      maxTimeRun === 100 ? 10 : 200
    )
  }

  const itemPicked = useCallback(number => {
    if (!swipeOn) {
      cardPicked(number)
    } else {
      handleSwipedStop()
    }
  })

  const createCarouselCSS = () => {
    let radius = carouselRadius
    if (carouselRadius === 0) {
      radius = getCarouselRadius()
      setCarouselRadius(radius)
    }
    var cellCount = getTotalCards()
    var theta = 360 / cellCount
    let styles = ""
    for (var i = 0; i < cellCount; i++) {
      var cellAngle = theta * i
      styles += `
      & > div:nth-child(${i + 1}) {
        transform: rotateY(${cellAngle}deg) translateZ(${radius}px);
      }
      `
    }
    setCarouselCSS(styles)
  }

  return (
    <>
      {!shuffleOn && cardNumber === 0 && (
        <ArrowBox
          flexDirection="row"
          positionBox="absolute"
          topP="45%"
          leftP="50%"
          height="100%"
          width="100%"
          maxWidth="1110px"
          margin="0 auto"
          alignItems="center"
        >
          <ArrowLeft pb="20px" color={toolColors[0]}>
            <ArrowCircleLeft
              size={30}
              onMouseDown={() => {
                handleArrow("left")
              }}
              onMouseUp={() => {
                handleSwipedStop()
              }}
              onMouseLeave={() => {
                handleSwipedStop()
              }}
              onTouchStart={() => {
                handleArrow("left")
              }}
              onTouchEnd={() => {
                handleSwipedStop()
              }}
            />
          </ArrowLeft>
          <GuessParagraph
            mb="1rem"
            ml="0.5rem"
            color="rgb(160 160 160)"
            fontSize="1.4rem"
          >
            SWIPE & PICK <Swipe size={16} />
          </GuessParagraph>
          <ArrowRight pb="20px" ml="auto" color={toolColors[0]}>
            <ArrowCircleRight
              size={30}
              onMouseDown={() => {
                handleArrow("right")
              }}
              onMouseUp={() => {
                handleSwipedStop()
              }}
              onMouseLeave={() => {
                handleSwipedStop()
              }}
              onTouchStart={() => {
                handleArrow("right")
              }}
              onTouchEnd={() => {
                handleSwipedStop()
              }}
            />
          </ArrowRight>
        </ArrowBox>
      )}
      <GuessBox
        alignItems="center"
        padding="0rem 1rem 1rem"
        width="100%"
        alignSelf="stretch"
        flex="1"
        justifyContent="center"
        positionBox="relative"
        onMouseLeave={() => {
          handleSwipedStop()
        }}
      >
        <Box
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          mt="0rem"
          positionBox="relative"
          width="100%"
          height="300px"
          margin="2rem"
          {...handlers}
        >
          <Container>
            <Carousel
              id="tarot-carousel"
              swipeOn={swipeOn}
              shuffleCSS={shuffleCSS}
              shuffleOn={shuffleOn}
              swipeDirection={swipeDirection}
              carouselCSS={carouselCSS}
              carouselRadius={carouselRadius}
            >
              {cardArray.map((item, index) => {
                return (
                  <CarouselFace2
                    key={`TarotCard${item.number}`}
                    number={item.number}
                    itemPicked={itemPicked}
                  />
                )
              })}
            </Carousel>
          </Container>
        </Box>
      </GuessBox>
    </>
  )
}

export default TarotController
