import React from "react"
import { Box, Image } from "../commonElements"
import TarotCoverImage from "../../images/tarot/A-COVER-80.jpg"
import TarotFlipCoverImage from "../../images/tarot/Flip-Cover.jpg"
import styled from "styled-components"

const FlipCardOuter = styled.div`
  width: 100%;
  height: 50%;
  perspective: 1000px;
  margin-top: auto;
  margin-bottom: auto;
`

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.15s;
  transform: scale(0);
  transition: transform 10s;
  ${props => props.flipCSS};
`

const FlipCardFrontFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`

const FlipCardBackFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`

const FlipImage = styled(Image)`
  object-fit: initial;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`

const TarotFlipCardModal = ({ cardNumber, flipOn, flipCSS }) => {
  return (
    <Box style={cardNumber !== 0 && flipOn ? { display: "flex" } : { display: "none" }}
      borderRadius="1rem"
      alignItems="center"
      width="100%"
      alignSelf="stretch"
      justifyContent="center"
      positionBox="absolute"
      zIndex="99"
      topP="0px"
      bottomP="0px"
      padding="3rem 2rem"
      maxHeight="60rem"
    >
      <FlipCardOuter>
        <FlipCardInner flipCSS={flipCSS}>
          <FlipCardFrontFace>
            <FlipImage
              borderRadius="0.5rem"
              src={TarotCoverImage}
              alt="Tarot Card"
              height="250px"
              width="auto"
            />
          </FlipCardFrontFace>
          <FlipCardBackFace>
            <FlipImage id="tarot-card-image-flip"
              borderRadius="0.5rem"
              src={TarotFlipCoverImage}
              alt="Tarot Card"
              height="250px"
              width="auto"
            />
          </FlipCardBackFace>
        </FlipCardInner>
      </FlipCardOuter>
    </Box>
  )
}

export default TarotFlipCardModal