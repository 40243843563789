export const CardsData = {
    "data": [
        {
            "id": 1,
            "title": "The Fool",
            "answer": "YES",
            "description": "This card begins the deck and is unnumbered, representing nothingness, infinity, a blank slate to fill. In a regular deck of playing cards, the Fool’s symbol would be the Joker. But don’t be fooled by the Fool.<br/><br/>The card exemplifies naïveté and innocence that disguises the innate wisdom within us all. The path of the Fool may yet be unclear, just as the future of a newborn remains to be written. But the Fool contains all possible permutations of the situations we encounter on our own personal journeys through Life. It is our individual responsibility to chart our own course as we go.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F0-Fool.jpg?alt=media&token=dfdd1551-ecf6-41b7-9602-fa0f9c779076"
        },
        {
            "id": 2,
            "title": "The Magician",
            "answer": "YES",
            "description": "The Magician is the Rainmaker, the one who makes the magic happen in plain sight. But be wary because the Magician can also be a trickster or a charlatan. Discernment is needed when the Magician shows up. However, this is the go-to card for success. The Magician possesses all the knowledge, tools, and skills necessary for you to be successful in life or on a project.<br/><br/>The card’s challenge is to integrate all the energy manifested by the Magician into your own personal blueprint for success. Read all the signs and don’t get caught up in something unethical or illegal.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F1-MAGICIAN.jpg?alt=media&token=31683587-6658-41be-b4a4-00a78416cfe6"
        },
        {
            "id": 3,
            "title": "The High Priestess",
            "answer": "MAYBE",
            "description": "The High Priestess card exemplifies feminine intuition. It tells you that you must look within yourself for the true answer to your query. The Moon rules the High Priestess, so you could have your answer within a month.<br/><br/>No action is represented by this card. Rather, the energy is “wait and see.” Let events unfold on their own. Some things cannot be rushed no matter how much we wish for them to occur. You also might not have all the information you need at your disposal now – and that’s OK because all will be revealed in its own time.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F2-HIGH-PRIESTESS.jpg?alt=media&token=0451a531-b54c-4510-92f7-382167b9ca6a"
        },
        {
            "id": 4,
            "title": "The Empress",
            "answer": "YES",
            "description": "Here is another card with feminine energy. The Empress is the female archetype and represents Gaia, or Mother Earth, the eternal nurturer of people, plants, and pets. All life springs from the Empress, making this a very powerful card.<br/><br/>The Empress represents reaping the harvest one sows and things coming to fruition in 9 months (like a pregnancy). But this positive energy could also relate to giving birth to a creative endeavor or project.<br/><br/>This card symbolizes emotional security and stability toward loved ones – and to the world at large. Self-love is encouraged to gather strength to protect others who might be otherwise vulnerable without this protection.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F3-EMPRESS.jpg?alt=media&token=87937131-d5e7-43dd-b8e7-2f238828d7b4"
        },
        {
            "id": 5,
            "title": "The Emperor",
            "answer": "NO",
            "description": "The Emperor is the masculine archetype in the tarot deck. Whereas the Empress symbolizes protecting those who are vulnerable, this patriarchal energy is far sterner and more restrictive. The Emperor represents authority figures who demand discipline and order from others.<br/><br/>Sometimes the Emperor is a literal representation of law enforcement officers or a judge. Other times in a reading, this card symbolizes a boss or supervisor. In a military situation, the rank would be a Captain or above. The Emperor, in modern parlance, is the shot-caller of the tarot deck. A stern demeanor may mask an innate desire to be fair.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F4-EMPEROR.jpg?alt=media&token=7cabeb0f-58f6-4a86-8931-be0eef1fa1a4"
        },
        {
            "id": 6,
            "title": "The Hierophant",
            "answer": "NO",
            "description": "This card has similar energy to the Emperor and represents limitations, rules, and order, often of a religion or sect. This person dispenses advice and might represent a psychiatrist – or an attorney. The card shows up in times of trouble when professional advice is desperately needed.<br/><br/>The energy of the Hierophant indicates a time to follow old rules and honor traditions. Now is not the time to implement sweeping changes in your life. Respect the wise counsel and don’t just give lip service to good advice.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F5-HIEROPHANT.jpg?alt=media&token=a1226030-b4bd-435e-b233-10d59bcd7743"
        },
        {
            "id": 7,
            "title": "The Lovers",
            "answer": "YES",
            "description": "The Lover symbolize both love and choices. Sometimes these go hand-in-hand, e.g., when choosing between two suitors. Other times, it can mean that you have choices to make in other areas of your life, so make sure that you choose your options carefully.<br/><br/>When dealing with love relationships, trust that your intuition will be right on point. Now is the time to follow your heart; don’t get too analytical. Remember that the heart wants what the heart wants, and love cannot be forced. It must be the right fit for both the partners for it to work.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F6-LOVERS.jpg?alt=media&token=bc20dccd-ab62-4733-8506-9ce69ed01cb7"
        },
        {
            "id": 8,
            "title": "The Chariot",
            "answer": "YES",
            "description": "The Chariot tells you that all systems are go, so rev up those engines! Your chariot race is on, and you are a contender. This card can indicate competition -- and you can have the upper hand. The caveat of this card is that victory requires directed willpower to manifest results.<br/><br/>You have to work hard to keep things between the lines so that your path remains unobstructed and clear. Don’t allow distractions to divert you from your goal. Remember, the reins are in YOUR hands. You’re in charge of your own destiny!",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F7-CHARIOT.jpg?alt=media&token=44c4dfa1-f215-423c-9529-9d8f81c850a2"
        },
        {
            "id": 9,
            "title": "Strength",
            "answer": "YES",
            "description": "Strength has equal parts masculine and feminine energies. It is yin balanced by yang. It takes strength to calm and quiet wild forces, but it also takes self-knowledge. You must be assured that you have the power within you to stand up and assert your rights to something larger or more imposing than yourself.<br/><br/>Strength is sometimes (but not always) associated with actual physical prowess. There are many kinds of strengths, and sometimes the strongest individual can also be the gentlest. Where do your strengths lie?",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F8-STRENGTH.jpg?alt=media&token=08932e28-f2ed-4fc4-b8b1-0b51f53dd311"
        },
        {
            "id": 10,
            "title": "The Hermit",
            "answer": "MAYBE",
            "description": "The Hermit teaches us that solitude does not equal loneliness. Rather, it urges us to turn toward the within, searching our own internal archives for the answers to our queries. In some circumstances, it can mean seeking answers from the stereotypical ascetic guru atop a tall mountain.<br/><br/>But mostly, the Hermit tells us to withdraw from the maddening crowd and take a quiet break. It can be helpful to unplug devices, power down phones and ignore the chaos raging outside your protective bubble. Within you is the power to manifest your own reality.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F9-HERMIT.jpg?alt=media&token=c95f9f26-5fc1-43db-a712-b14493d90d08"
        },
        {
            "id": 11,
            "title": "Wheel of Fortune",
            "answer": "MAYBE",
            "description": "The Wheel of Fortune is all about luck and fortune and the karmic cycles of life. One thing is certain -- a change is on the way. Consider what is going on right now, and steady yourself for changes to come, as change is the only certainty in life. In circumstances of timing, these changes will arrive sooner than you realize. Be ready.<br/><br/>More people view this card as positive than they likely should because of the nature of gambling. You are more likely to lose than to beat the house when you gamble. If things are good, they will soon be worse. But when luck runs cold, expect it to improve in short order.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F10-WHEEL-OF-FORTUNE.jpg?alt=media&token=c8c2f873-8cc8-4df4-8a57-ec4f424e839f"
        },
        {
            "id": 12,
            "title": "Justice",
            "answer": "MAYBE",
            "description": "Justice is another card that doles out the karma one has generated. If you ran afoul of the law, you might be shaking in your boots. But if you were wrongly accused of an offense, you could see the wrong righted and your name cleared.<br/><br/>Justice tells you that you cannot outrun your fate. You must pay for misdeeds and will be rewarded for the charity and grace you have dispensed. The good news is that one can always change the path they are presently on and plot a different (and karmically better) course.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F11-JUSTICE.jpg?alt=media&token=e176dfc4-8387-4a8e-a29d-b87fd1fbf71f"
        },
        {
            "id": 13,
            "title": "The Hanged Man",
            "answer": "NO",
            "description": "The Hanged Man symbolizes a lack of motion, either forward or backward. You might not be gaining ground, but you are losing none either. Use this time of stasis to consider your options and wait things out until it is time to make a decision or move.<br/><br/>The Hanged Man asks us to seek and be true to our higher values, as this will help us extricate ourselves from a period of boredom and entropy. You might need to sacrifice something to move ahead – job, relationship, thought patterns.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F12-HANGED-MAN.jpg?alt=media&token=ca21c286-2abb-43f6-b447-5327a26d7c64"
        },
        {
            "id": 14,
            "title": "Death",
            "answer": "NO",
            "description": "Death is likely the most misunderstood card of the deck. It very rarely means a physical death (although that remains a possibility). Like the sign of Scorpio that rules over it, Death is all about endings and transformations. Death can be a painful card to integrate because it involves transformation masquerading as loss.<br/><br/>Don’t cling to the past and forget to look to the future. In the past lie the lessons we have yet failed to learn. Physics teaches that “energy can neither be created nor be destroyed [but] it may be transformed from one form to another.” Therefore, this card is not negative but part of the natural cycle of life.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F13-DEATH.jpg?alt=media&token=43c783ac-ec23-4d8b-a846-3bedffb63ab1"
        },
        {
            "id": 15,
            "title": "Temperance",
            "answer": "MAYBE",
            "description": "Temperance teaches us that the only proven path to peace in life is moderation. A little of this but not too much of that. It is a constant tightrope walk to get it right, but as all things do with practice, it becomes easier with each attempt.<br/><br/>Where do you go overboard in your life and where do you lack effort, time, or commitment? Are you obsessing about one thing to the exclusion of others? Do your own internal checklist and give yourself an auto tune-up. It might be overdue.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F14-TEMPERANCE.jpg?alt=media&token=1e59275b-280b-4cd1-859f-35d00252c3ff"
        },
        {
            "id": 16,
            "title": "The Devil",
            "answer": "NO",
            "description": "The Devil doesn’t epitomize moderation. Its motivation is always greed and avarice. It seeks to bind you to your weakness through obsessions and addictions. Whatever is your Achilles’ heel – sex, drugs, drink, betting on the ponies . . . whatever you can’t refuse – is what the Devil seeks to exploit.<br/><br/>Your job is to break free from those ties that bind you to unhealthy things that prevent you from progressing on your path through life. The Devil is not some nightmarish monster. It represents the immoral temptations to go astray that live within us all.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F15-DEVIL.jpg?alt=media&token=24c3c3a9-db91-4951-9688-c36e2db93c0d"
        },
        {
            "id": 17,
            "title": "The Tower",
            "answer": "NO",
            "description": "The Tower is, to most querents, the least welcome tarot card in the deck. This is a card of wild upheaval, and it is headed your way fast. Break-ups, divorces, job terminations, and/or housing crises all can be heralded by The Tower. Its reputation is both unnerving and deserving when taken at face value.<br/><br/>But there is also some vestigial remnant of hope that remains, just as with the mythical phoenix arising from the burnt pyre of its ashes. Good things can come from bad endings if we are willing to open ourselves up to the opportunities.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F16-TOWER.jpg?alt=media&token=091b67c3-985b-4855-b3a9-a0134d38b848"
        },
        {
            "id": 18,
            "title": "The Star",
            "answer": "YES",
            "description": "The Star card is your beacon in the night. This is a card of hope and renewal. Follow your star to navigate your way through the wilderness. The Star represents both fixed and mutable energies, symbolizing when to flow with the water and stand grounded on the earth.<br/><br/>When the star shows up in your reading, there is always hope. It is up to you to nourish it and bring it to fruition. In the most literal of interpretations, the Star can indicate an actual path to stardom if that is the destiny you choose to pursue.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F17-STAR.jpg?alt=media&token=1d1702aa-16a0-4c71-afd1-973be90bc41f"
        },
        {
            "id": 19,
            "title": "The Moon",
            "answer": "NO",
            "description": "Just as the Moon passes quickly through its phases every month, events are constantly in flux. Things appear to be illusory, and they are subject to change rapidly. The Moon tells us to allow ourselves to be guided by intuition and life’s natural patterns. The Moon asks us to embrace the inherent feminine energy that thrives within all humanity. Don't be afraid to show sensitivity to finesse a delicate situation.<br/><br/>Since there is no clarity now, don’t rush to act. Hidden forces can be at work behind the scenes pulling strings. Sit back, observe, and take it all in for now. When dealing with a situational question, the resolution should come within a month.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F18-MOON.jpg?alt=media&token=714cbfe6-4688-4fd1-80e2-2c9ef9afd555"
        },
        {
            "id": 20,
            "title": "The Sun",
            "answer": "YES",
            "description": "Joy of joys, the Sun is shining brightly upon you. The Sun card assures all is well with your world. Its energy is the opposite of the Moon. The Sun brings what was hidden out into the light. You are illuminated by the truth and no longer subjected to deception.<br/><br/>The Sun also sparks joy in our lives. Find that one thing that will fill your heart to bursting and go for it. YOLO. Don’t miss the opportunity to shine. If the conditions weren’t right before for a venture or a new enterprise, they are now. Carpe diem!<br/><br/>As a side note, the Sun can indicate changes to come over the course of a year.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F19-SUN.jpg?alt=media&token=0056ee2e-2b09-40a7-98d7-60631d1b343e"
        },
        {
            "id": 21,
            "title": "Judgement",
            "answer": "MAYBE",
            "description": "We will all face a final accounting, and your actions will determine your fate. Judgment is the final karmic card in the Major Arcana and represents shedding your old life so you can transition to another phase. You might quit a job and find a better one making more money in another field due to your stellar work reputation.<br/><br/>Likewise, your spouse could permanently kick you to the curb for your cheating ways. Judgment is the Accountability card. It encourages us to evolve and grow positively so that we may live our best lives.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F20-JUDGEMENT.jpg?alt=media&token=a3f0e046-9987-46e4-8537-4bac2f217d1d"
        },
        {
            "id": 22,
            "title": "The World",
            "answer": "MAYBE",
            "description": "The World is the final card of the Major Arcana. When you reach this card, you have figuratively traveled the path of the Fool through each card and arrived at the end of your journey from innocence to worldliness. If you have absorbed the lessons of the cards, you have all the tools and knowledge you need to go out and conquer the World on your own terms.<br/><br/>If you fail to integrate the lessons of the tarot, you could be unprepared and need to retrace your steps and relearn some important life lessons.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F21-WORLD.jpg?alt=media&token=268becfa-74eb-4461-be10-72fcf5d56061"
        },
        {
            "id": 23,
            "title": "ACE of WANDS",
            "answer": "YES",
            "description": "If you are looking for affirmations, this card signifies unequivocal positivity. All Aces indicate a time for new beginnings. You are driving the mule-train, so to speak, so make sure that you are confident in your ability to remain in control.<br/><br/>Don’t become a victim to your own hubris, however. Remember that to those whom much is given, much is also expected. Never let your principles be compromised to get ahead.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2FAce-of-wands.jpg?alt=media&token=b947514e-d992-4b8b-8e90-3aa2184a209e"
        },
        {
            "id": 24,
            "title": "TWO of WANDS",
            "answer": "MAYBE",
            "description": "You may soon need to make a choice – whether to remain safely where things are familiar or to venture out into the wider world and make your mark. Which option will you choose? Safety or taking the bold leap? Whichever option you choose, get all the facts first to ensure that you are well-poised for either plan.<br/><br/>Understand that there is nothing intrinsically wrong with choosing what is familiar and known. Sometimes, that might be the most viable choice. But also know that either choice can permanently close the door to options you will never have again.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F2-of-wands.jpg?alt=media&token=bb36b7a9-3a09-467e-b802-bacb6642b8f2"
        },
        {
            "id": 25,
            "title": "THREE of WANDS",
            "answer": "YES",
            "description": "Don't rest on your laurels! You need an action plan if you don’t already have one. There is an entire world out there to conquer, so get ready to level up and expand. Now is not the time to dither. Go big or go home. You want to be in the best position possible for success in all ventures. Do your homework, check your facts, and make a move. It won’t be wrong!",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F3-of-wands.jpg?alt=media&token=a3bdf1c7-f4b6-4cb4-91a7-0a0d3d721b5d"
        },
        {
            "id": 26,
            "title": "FOUR of WANDS",
            "answer": "YES",
            "description": "The party is on! Celebration is in the air, so let yourself partake and enjoy the good life you share with friends and family. This is the card of extroverts, of optimists, of those who understand the inherent value of stable and positive relationships in their lives. When this card appears, prepare to have a rollicking good time among those whom you love.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F4-of-wands.jpg?alt=media&token=fe49df42-04c3-42d0-920d-0a5ee72e7c12"
        },
        {
            "id": 27,
            "title": "FIVE of WANDS",
            "answer": "NO",
            "description": "The 5 of Wands indicates conflicts with others. But sometimes, too, it signifies an inner struggle you battle alone. If you are challenged by your relationships with others, ask yourself the hard question: Is it really them or could it be you?<br/><br/>That answer can be quite illuminating. Your own frustrations with your human frailties can lead you to take it out on others, so own your own in these situations if you want to resolve the matters.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F5-of-wands.jpg?alt=media&token=df1bacbb-0974-4fe4-b1f0-eb6355332bb9"
        },
        {
            "id": 28,
            "title": "SIX of WANDS",
            "answer": "YES",
            "description": "It has been said that “’V’ is for victory!” But perhaps what should be said is the 6 of Wands is for victory. Step back and let the accolades roll in. However, if your spouse, work partners, team members, or other allies were an integral part of your hard-won victory, make sure that you include them and recognize their contributions as well as your own.<br/><br/>Now that you have tasted success, reach down and help others get their fair chance to grasp the brass ring. Doing these charitable deeds for the less fortunate helps the karmic wheel turn ‘round.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F6-of-wands.jpg?alt=media&token=7b605c44-a082-4b02-bb6f-f08bbc1bf910"
        },
        {
            "id": 29,
            "title": "SEVEN of WANDS",
            "answer": "MAYBE",
            "description": "The worm has turned, and it seems like you are back on the battlefield attacking new challenges. In the immortal words of the late Tom Petty, “You can stand me up at the gates of Hell, but I won’t back down.”<br/><br/>If it is time to defend your home or your homeland, stand up tall and strong and take on the challenge to the best of your ability. You won’t win every battle you fight but you sure can give it your all. You may win this one, too. Hard-won victories are often the best to savor, anyway.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F7-of-wands.jpg?alt=media&token=a54e13ae-0bdb-48f8-aa81-b4c916fdf3e3"
        },
        {
            "id": 30,
            "title": "EIGHT of WANDS",
            "answer": "YES",
            "description": "This is a card of fast and furious activity. Whatever plans or actions that have been set into motion are likely unstoppable. This card can signal travel, particularly by air. It indicates that obstacles have been removed, leaving room for progress.<br/><br/>You could also wind up swept off your feet in a whirlwind romance. All your red lights have turned green, so go, go, go!",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F8-of-wands.jpg?alt=media&token=8fe8bfd3-b7d8-4795-b509-15b33b47eeaf"
        },
        {
            "id": 31,
            "title": "NINE of WANDS",
            "answer": "MAYBE",
            "description": "Stop picking at old wounds. If you keep pulling off the scabs of your heartache, you will never fully heal.<br/><br/>In other venues, you could succumb to a bout of Imposter Syndrome at work. Don't doubt yourself or your skill set. If you really feel outmatched at work, take some supplemental courses, and brush up on your weak spots.<br/><br/>Also, there are many wars to wage, but pick your battles strategically. A series of small wins can energize you for the big campaign you will face later. Don’t be paranoid but be aware of others and their sometimes self-serving motivations.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F9-of-wands.jpg?alt=media&token=45b37030-0560-4d67-9a99-a54e79cab601"
        },
        {
            "id": 32,
            "title": "TEN of WANDS",
            "answer": "NO",
            "description": "This is the card of the workaholic, someone who devotes all their time and energy to their job. Remember, nobody on their deathbed ever wished they spent more time at the office. Still, you have a hard row to hoe ahead, so consider delegating tasks to work subordinates. Everybody is replaceable, so stop to smell the roses.<br/><br/>Sometimes, the burdens are all related to home and family. Caregiver burnout can add stress, so seek help from family members or look into available resources in your community that can provide you with some respite.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F10-of-wands.jpg?alt=media&token=60c9d832-87d4-4430-90fa-667564a09f9c"
        },
        {
            "id": 33,
            "title": "PAGE of WANDS",
            "answer": "YES",
            "description": "The Page of Wands symbolizes optimism and youthful enthusiasm, whether it be at work or in the game of love. Freely express your choices and spontaneity. This Page can indicate acts of bravery that bring recognition and merit to your efforts.<br/><br/>This card might also caution you to engage your brain before opening your mouth. It tells you to look before making that leap. Restraint might be challenging for you, but it is necessary for successful outcomes.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fpage-of-wands.jpg?alt=media&token=60b10091-d6a5-4bb9-9066-8a5dfa0b56f8"
        },
        {
            "id": 34,
            "title": "KNIGHT of WANDS",
            "answer": "YES",
            "description": "The Knight of Wands symbolizes fiery passion. This person brings their “A” game every time. They love hard and strongly, but maybe not for very long. When the Knight of Wands appears, it indicates the need to prioritize your relationships with all your loved ones.<br/><br/>Career-wise, the trajectory of your star continues to rise in the workplace. You may need to take some mighty big risks, so do your homework and don’t try to fly by the seat of your pants. Preparation will guide you to success.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fknight-of-wands.jpg?alt=media&token=67c415c5-47bd-4925-8a05-1bc5fdf50f9d"
        },
        {
            "id": 35,
            "title": "QUEEN of WANDS",
            "answer": "YES",
            "description": "The Queen of Wands leads, and others follow. The card symbolizes someone who knows their own value, whether it be in the bedroom or the boardroom. The Queen is the promise of the hunt, an individual able to inspire others to go the distance just to get approval.<br/><br/>The Queen of Wands represents a person who commands respect from all. You might want to emulate the Queen of Wands, but an inherent bossiness sets the Queen of Wands apart from many people. This is less a superiority complex and more a desire to bring about the best results. If this card represents you, learn to harness that innate creativity to produce works of Art or just deliver your personal best in whatever you may take on.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fqueen-of-wands.jpg?alt=media&token=7625f9ad-6f5e-4060-a190-965bd66d4607"
        },
        {
            "id": 36,
            "title": "KING of WANDS",
            "answer": "YES",
            "description": "This card embodies tremendous energy and the urge to create a better, brighter future. The King of Wands is forward-thinking, but not just a dreamer with their head in the clouds. This King understands how to strategize to get the most advantage out of any situation.<br/><br/>As a lover, the King of Wands is hot to trot and burning with passion. Sometimes, that passion gets channeled into creative projects, paintings, Art, big productions. When the card appears in a reading, there is the drive and energy to manifest your dreams and make them reality.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fking-of-wands.jpg?alt=media&token=8413062f-d3f0-43bd-bd97-a4592a8229eb"
        },
        {
            "id": 37,
            "title": "ACE of CUPS",
            "answer": "YES",
            "description": "Aces bring changes, and this Ace brings welcoming energy to new love connections as well as burgeoning friendships. Cups are the equivalent of hearts in a deck of playing cards. They represent the eternal flow of water which intuitively finds its form as it flows along its course.<br/><br/>The Ace of Cups represents pure love in all its forms. The world has not yet corrupted this type of love, so be mindful to protect it from the slings and arrows that can destroy its vulnerable purity.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Face-of-cups.jpg?alt=media&token=960fa0a5-a257-4654-a7a2-8c21deb4f307"
        },
        {
            "id": 38,
            "title": "TWO of CUPS",
            "answer": "YES",
            "description": "This is the “soulmate” card, symbolizing that the whole is greater than the sum of its parts. What you and this soulmate can forge together is unstoppable. Not only love but support is available to you now.<br/><br/>Partnerships of all sorts can be symbolized by this card. Remember to treat one another as equals in all your interactions to get the best contributions from all involved in work projects or shared agendas.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F2-of-cups.jpg?alt=media&token=109b7cbd-b804-420c-bc7d-7e204163a73e"
        },
        {
            "id": 39,
            "title": "THREE of CUPS",
            "answer": "YES",
            "description": "The 3 of Cups signals prosperity and accomplishment as a reward for working toward common goals. Once the work has all been done, it is time to celebrate your achievements. In a world where “otherness” and competition create divisiveness at every turn, much can be gained by a collaborative spirit and an open-hearted willingness to work together toward common goals. This card is symbolic of prosperity through unified efforts.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F3-of-cups.jpg?alt=media&token=4a36140f-9394-46b1-a3df-65200de67ddb"
        },
        {
            "id": 40,
            "title": "FOUR of CUPS",
            "answer": "MAYBE",
            "description": "This card demands that choices must be made. Which choice will you make? Have you turned so inwardly into yourself that you are incapable of acknowledging choices that are practically being handed to you?<br/><br/>What to do, what to do? Climb out of your inner shell and show some interest in the world around you! Prioritize the decisions you face. Shake the clouds from your head and end your inner monologue. Act now, or choices will be made for you.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F4-of-cups.jpg?alt=media&token=abbf1cfe-0687-44f4-aab1-ce1a53e4cad0"
        },
        {
            "id": 41,
            "title": "FIVE of CUPS",
            "answer": "NO",
            "description": "There is pain here, and for whatever reason, you are unable to let it go. Do you prefer to weep and moan over your losses than to be grateful for what you already have in front of you? Maybe you are pining for an ex who no longer loves you. Will you just sit there and dwell on the past or put your big-kid pants on and move on?<br/><br/>Negative emotions may be unavoidable at times, but they can take over your life if you aren’t careful. Shrug off your sadness and begin walking a new path.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F5-of-cups.jpg?alt=media&token=e5de3065-d4d1-4bdf-8c21-293eb84b8838"
        },
        {
            "id": 42,
            "title": "SIX of CUPS",
            "answer": "YES",
            "description": "Turning to the past can cause waves of nostalgia to wash over you. Remember the security and stability you felt as a child? You may want or need to replicate those feelings now, for yourself or those close to you.<br/><br/>Looking back on those simpler days, what did you experience that brought you the most happiness? Was it a first love? A childhood friend? The enveloping love of a doting parent?<br/><br/>You might want to reach out and try to reconnect with them over social media or even snail mail. Connections are important, so cherish the ones you have.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F6-of-cups.jpg?alt=media&token=7bbc9430-88b3-46c9-9665-9a07560177cb"
        },
        {
            "id": 43,
            "title": "SEVEN of CUPS",
            "answer": "NO",
            "description": "Some things don’t appear to be what they seem. Having too many choices may be literally driving you mad. You are not using discernment at this time, and it can bring disastrous results to your life.<br/><br/>Remember that all that glitters is not gold. Some “surprises” are not the good kind, either. You could discover you hooked up with a sociopath and wind up stuck in a nightmare relationship. Learn how to discern and distill what is real from what is fake, and you will lose a lot of the stress that dogs your steps.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F7-of-cups.jpg?alt=media&token=00e0c056-b0da-472b-bfaf-8e195e51f211"
        },
        {
            "id": 44,
            "title": "EIGHT of CUPS",
            "answer": "NO",
            "description": "Are you realizing that you walk your path alone? A solitary sojourn does not have to be sorrowful or meaningless. If you are unhappy with your lot in life, you have the power to change it.<br/><br/>Being fearful of changes and solitude is understandable. But it shouldn’t be so all-consuming that it leaves you unable to go forward. Your emotions may lighten as you progress and encounter others who can share your burdens -- and your joys.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F8-of-cups.jpg?alt=media&token=442f6f75-a951-4e5a-99eb-66de9f0d5f63"
        },
        {
            "id": 45,
            "title": "NINE of CUPS",
            "answer": "YES",
            "description": "You got the Wish Card! Yay! There are few cards with more positive connotations than the 9 of Cups. It literally means you can now attain your heart’s desire. This card heralds a new cycle of good luck, prosperity, love, and happiness.<br/><br/>What is your dream? Do you want to travel? Become your own boss? Maybe marry the boss? Perhaps for the first time ever, all systems are go. Don’t waste this positive energy! Manifest it into your reality. You can do it!",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F9-of-cups.jpg?alt=media&token=1286b2ec-f633-4897-9226-e73f01cfefad"
        },
        {
            "id": 46,
            "title": "TEN of CUPS",
            "answer": "YES",
            "description": "Oh, happy day! You and your soulmate can now rejoice in gratitude for all that life has provided you. Your children are safe and secure, and all is right in your little corner of the world. How does it feel to have all you ever wanted?<br/><br/>If happiness has eluded you for too long, those days are gone. Abundance and good luck await you. Stride toward the future with confidence and open arms extended to welcome your loved ones close in a hug. It just doesn’t get much better than this.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F10-of-cups.jpg?alt=media&token=83733641-8b9f-4183-a80b-5a208f3bbee1"
        },
        {
            "id": 47,
            "title": "PAGE of CUPS",
            "answer": "YES",
            "description": "Is someone expecting a child or trying to get pregnant? Maybe they have just announced an engagement. This card signifies good news is on the way. Because this Page is a Cup, it is likely related to love.<br/><br/>But there could be other types of “birth” stemming from your creative endeavors: a finished manuscript, a business that’s about to open, some other project in that same creative vein getting off the ground. Just remember that you must follow up the lucky break with the work to pull it off. Your intuition is sharp now, so tune in!",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fpage-of-cups.jpg?alt=media&token=3d09e510-0d69-4123-ab99-b0e791955f2b"
        },
        {
            "id": 48,
            "title": "KNIGHT of CUPS",
            "answer": "YES",
            "description": "Love is in the air, so stop playing hard to get and start sharing your love with a deserving guy or gal. You can have lots of fun with your lover and still build a deep and lasting relationship together.<br/><br/>It can be downright scary to follow your heart instead of your head – especially if you aren’t used to doing that. You don’t have to throw all caution to the wind but give yourself a chance to welcome love into your world.<br/><br/>You could discover love in the workplace when you ace that three-round job interview you’ve been sweating over. Regardless, enjoy your work and build your earning power.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fknight-of-cups.jpg?alt=media&token=da8995c4-04bf-45fe-bf42-2567e3bdaec2"
        },
        {
            "id": 49,
            "title": "QUEEN of CUPS",
            "answer": "YES",
            "description": "True love begins with self-love. Make sure that you are taking good care of yourself, as this shows others that you value yourself and can value them as well. Don’t deceive others or yourself by putting on a happy face when you really want to cry.<br/><br/>When this Queen turns up, it is a sign of an emotionally healthy, well-balanced individual who is stable and secure in their position. Never let jealousy steal the joy from an important relationship.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fqueen-of-cups.jpg?alt=media&token=155b5153-6221-41e9-a380-6d588bb85625"
        },
        {
            "id": 50,
            "title": "KING of CUPS",
            "answer": "YES",
            "description": "This King is stable and mature, capable of providing for loved ones and family. It represents a balanced approach toward all aspects of life and the masculine and feminine qualities contained within all of us. You can turn to this person for advice and support when you feel adrift.<br/><br/>As an aspect of the querent, use tact and diplomacy in all your endeavors. It is easier to lure someone with honey than with vinegar. Just make sure that what you have to offer is genuine and true, because the King of Cups will read you like an open book and see what is in your heart.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fking-of-cups.jpg?alt=media&token=040b686e-30cc-4986-aee4-fed4a596b76a"
        },
        {
            "id": 51,
            "title": "ACE of SWORDS",
            "answer": "YES",
            "description": "Swords are never the querent’s favorite cards in the tarot deck. That is because they show up when we are either in trouble, expecting trouble or trying to get out of trouble.<br/><br/>This card is a particularly potent Ace because, in a reading, it shows you how to extract yourself from all forms of adversity. Then, as a bonus, the Ace helps you turn that adversity around, harness its negative power, and manifest it into something powerful and positive for your own benefit!",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Face-of-swords.jpg?alt=media&token=bbbe0249-cfe4-4913-a57b-0a56c80134c9"
        },
        {
            "id": 52,
            "title": "TWO of SWORDS",
            "answer": "MAYBE",
            "description": "Ah, choices. Which way will you go? It can be hard to make a choice when you can’t see the real picture. There’s a good chance that someone is hiding some essential information from you. That withheld information would be especially useful to you when making your choice.<br/><br/>Be careful not to turn a “blind eye” to unwelcome information simply because it is something you don’t want to know. Knowledge is power, and it is time to take yours back. Learn all you can about what lies beneath the surface. Then, make the best choice you can to defuse the conflicts you face.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F2-of-swords.jpg?alt=media&token=b0766052-45aa-41c5-ae9d-4a271ffb0c2f"
        },
        {
            "id": 53,
            "title": "THREE of SWORDS",
            "answer": "YES",
            "description": "Your heart is broken, and you feel as if it will never heal. You may have experienced a trio of life-altering blows that brought you to your knees in despair. You need respite from this pain to heal, but you also must hold onto the lifeline that hope provides.<br/><br/>When we love, we become vulnerable because that love can be taken away in an instant. Your job is to keep from allowing your pain to destroy all that is good and pure and loving within your pierced heart that aches so badly. You are strong enough to see this through.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F3-of-swords.jpg?alt=media&token=a1ab7bf3-c720-4b76-b6cd-11f548af4540"
        },
        {
            "id": 54,
            "title": "FOUR of SWORDS",
            "answer": "NO",
            "description": "The 4 of Swords urges rest. You could be so worn out from the trials of life or actual physical struggles that you need a period of rest to recharge your batteries for what lies ahead.<br/><br/>You are not the Energizer Bunny. You cannot keep up a frantic, frenetic pace without paying dearly for it. While overall there is negativity associated with the 4 of Swords, there is also hope and potential assistance available to you as well. Focus on the hope as you recuperate so you can rejoin the fray another day.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F4-of-swords.jpg?alt=media&token=4f5b20cd-d4c2-416b-8ecc-c395cc13436a"
        },
        {
            "id": 55,
            "title": "FIVE of SWORDS",
            "answer": "MAYBE",
            "description": "Some days it can be hard to determine who is a friend and who is an enemy. This card is your clarion call warning you will soon be within someone’s crosshairs.<br/><br/>Now is the time to ready yourself for battle. Don’t be surprised to learn that you are fighting this battle alone. Your way out of danger is through communication. Speak your mind and make your feelings known. Although you’re facing trouble, help can come from unexpected sources.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F5-of-swords.jpg?alt=media&token=56fa9c23-5637-43f2-9710-ea9ab69b771e"
        },
        {
            "id": 56,
            "title": "SIX of SWORDS",
            "answer": "YES",
            "description": "When it comes to Swords, troubles are a-plenty. This time, you may need to move house and put a great deal of yonder between you and from whence you came. You don’t have to fight the same old battles day after day. You have options.<br/><br/>But sometimes people carry their problems away with them. Lighten your load and leave your burdens behind you. Then, clear your heart for the hope that lies ahead for you.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F6-of-swords.jpg?alt=media&token=79f81094-3266-40a0-b5f7-bcbc3067a8b4"
        },
        {
            "id": 57,
            "title": "SEVEN of SWORDS",
            "answer": "NO",
            "description": "Someone is stealing from you – or could it be the reverse? Regardless, deception and duplicity abound, and someone’s disloyalty is about to be discovered. You don’t want to be the one caught with your hand in someone else’s cookie jar, so make sure that all your actions are above-board and unimpeachable.<br/><br/>If the person is taking from you, you can trip them up and trap them. However, the overall negativity of this card remains strong regardless.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F7-of-swords.jpg?alt=media&token=6841c39c-fd76-463b-97d3-84468686b980"
        },
        {
            "id": 58,
            "title": "EIGHT of SWORDS",
            "answer": "NO",
            "description": "Are you bound by emotional ties from which you believe you can’t break free? In reality, you are your own captor by allowing yourself to falsely believe you are trapped. You have the ability to walk away from what you only perceive to be your prison.<br/><br/>Expanding your mind and learning about diverse cultures, beliefs, and solutions to your dilemmas offers you an unrestricted fresh start. There is never a need to stay stuck.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F8-of-swords.jpg?alt=media&token=bfebabe8-0040-4d5d-88f1-53bd4c0ff49b"
        },
        {
            "id": 59,
            "title": "NINE of SWORDS",
            "answer": "NO",
            "description": "The Nightmare card. All those negative thoughts, hurtful words, and procrastinated actions we try our best to ignore during the day come out swinging at night. If not nightmares, insomnia could be plaguing you.<br/><br/>Advocate for yourself, and for your mental, emotional, and physical health. There is no real-life Prince Charming on their way. Take charge of your troubles and put them into the proper perspective. Get the help you need to heal in all ways.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F9-of-swords.jpg?alt=media&token=8da6bd24-cd08-4b89-ad91-ebcc8ba58a30"
        },
        {
            "id": 60,
            "title": "TEN of SWORDS",
            "answer": "NO",
            "description": "If you ever truly wondered what rock bottom was like, welcome to it now. With some situations, there is no coming back, and this is one of those. You have been abused and mistreated in every way, shape, and form. Nothing good can come of this.<br/><br/>But the cycle has ended. Your challenge now is to recover and move on. There is a better life out there waiting for you. Gather your strength, shore up your support, and then go.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F10-of-swords.jpg?alt=media&token=df79c69b-6986-49dc-a313-0dbbdab1252f"
        },
        {
            "id": 61,
            "title": "PAGE of SWORDS",
            "answer": "YES",
            "description": "Have you ever heard the adage, “If you don’t stand for something, you stand for nothing?” Well, that is what this Page symbolizes. They have recently become “woke,” in modern parlance. The stand they are now taking is attributable to that “wokefulness” experience. They are ready to fight for justice for the downtrodden, the marginalized, those truly bereft of hope and dreams.<br/><br/>Youth, pride, and enthusiasm are on your side and will help you emerge victorious over injustice. What you might lack in experience, you can make up for with your ardor for the cause.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fpage-of-swords.jpg?alt=media&token=b1b1aadd-d20d-43e9-993f-c25fc6bbca19"
        },
        {
            "id": 62,
            "title": "KNIGHT of SWORDS",
            "answer": "YES",
            "description": "This Knight goes where angels fear to tread. The bravery of the Knight of Swords is well-known, but the Knight lacks impulse control. When this card shows up, ask yourself who you are fighting. Do you understand what brought you to this point – and more importantly – how to get yourself out of such a precarious position?<br/><br/>The lesson here is to look before you leap and only enter a battle if and when you are well-prepared. Otherwise, your efforts could wind up blowing away on the wind.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fknight-of-swords.jpg?alt=media&token=57fc6c06-1029-4c4d-9b97-d6524f6754b8"
        },
        {
            "id": 63,
            "title": "QUEEN of SWORDS",
            "answer": "YES",
            "description": "This Queen likely represents a single person. Their partner may have died, they may be divorced or never got married. They are fine with their status as an independent person.<br/><br/>People turn to the Queen of Swords with their problems. This Queen’s wise counsel guides many troubled souls along their paths. The Queen of Swords is symbolic of walking the road less taken. Solitude is not feared but revered for the enlightenment it brings. The Queen is empathetic but demands respect. Whether the Queen is a kind friend – or a formidable foe – is up to you!",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fqueen-of-swords.jpg?alt=media&token=a6328351-1de9-4492-a281-8e0bd9b66844"
        },
        {
            "id": 64,
            "title": "KING of SWORDS",
            "answer": "MAYBE",
            "description": "You don’t have to swagger to be fully aware of your strength and power. Value your own moral code you swore to uphold.<br/><br/>If you are feeling weak or downtrodden, know that you can turn to this King for support and guidance when you most need it.<br/><br/>This King has a weakness to tend to “rise above it all.” Details matter, so be careful not to become so oblivious that you miss the most important aspects of a situation, project, or relationship.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fking-of-swords.jpg?alt=media&token=2f9d97f2-914e-452e-b41e-061886c60d9b"
        },
        {
            "id": 65,
            "title": "ACE of PENTACLES",
            "answer": "YES",
            "description": "You might be overdue in receiving some good financial news. You could feel like you have worked hard and received little to compensate you for your time and efforts.<br/><br/>But when the Ace of Pentacles comes winging in, it brings good tidings about money and resources. That tax refund you never expected or an unexpected check for oil royalties (because Pentacles are earth signs and oil comes from deep in the ground) could be out there now in your mailbox. Go check!",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Face-of-pentacles.jpg?alt=media&token=b86418a3-a20f-4625-aa37-bd8a8c5ae69d"
        },
        {
            "id": 66,
            "title": "TWO of PENTACLES",
            "answer": "MAYBE",
            "description": "It can be hard to keep all your balls in the air while juggling. This card can indicate that resources are stretched too thin right now or that you have obligated yourself to too many duties or plans. One person can only take on so much without coming up short somewhere. It may be time to reassess your priorities and cut away the chaff from the wheat.<br/><br/>You might find help coming to you from unexpected sources. The key to best results here is knowing whom to ask for help when you find yourself in need.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F2-of-pentacles.jpg?alt=media&token=39f278f3-0876-472a-b66f-8ecdb790b9f4"
        },
        {
            "id": 67,
            "title": "THREE of PENTACLES",
            "answer": "YES",
            "description": "This card deals with group efforts and collaboration with friends and colleagues. You never want to take more than you give from these projects and plans. Are your friendships well-rounded sources of support, with each person contributing their share of resources and efforts? If so, your outcome is guaranteed to succeed.<br/><br/>If not, prune away the deadwood from your life and seek new sources of growth to help manifest your intentions. Your limitations may be only self-imposed.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F3-of-pentacles.jpg?alt=media&token=fe14e682-4524-4fea-91eb-50d962684ce7"
        },
        {
            "id": 68,
            "title": "FOUR of PENTACLES",
            "answer": "NO",
            "description": "This card indicates greed and superiority. The miser’s sign. If you have “made it” in this world, isn’t it time to share the wealth? Remember that in life we reap what we sow. Holding all your money tight to you may preserve your wealth – but at what cost? The suffering of others? Don’t be afraid to share your bounty and good fortune with others who might not be as fortunate as you have been thus far.<br/><br/>Keep in mind that all your good fortune can change in a second. A plunging stock market, a bad investment, a discouraging diagnosis can all strip from you what you assumed was yours for life.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F4-of-pentacles.jpg?alt=media&token=80e827d0-bd5c-4113-9d81-466666bd0158"
        },
        {
            "id": 69,
            "title": "FIVE of PENTACLES",
            "answer": "NO",
            "description": "Little is positive in this card. It can indicate poverty, debilitation, and destitution. Facing tough times like those can make people lose their faith. But few of Life’s worst experiences are entirely devoid of hope. Don't ignore potential assistance you can access.<br/><br/>Living so long on next to nothing can create the impression that is all that you can expect from this life. If that resonates with you, turn again toward the light, and seek out what resources you can gather.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F5-of-pentacles.jpg?alt=media&token=b5b09d4c-01f3-4732-bb3e-27b2507be3d4"
        },
        {
            "id": 70,
            "title": "SIX of PENTACLES",
            "answer": "YES",
            "description": "You can dispense both generosity and grace as you make your way through your life. Use the gift of discernment to show justice and fairness in all your actions. Don’t be miserly and cling miserably to money or possessions rather than share your bounty with others. Give willingly when you see a genuine need.<br/><br/>Maybe you are the one who is in need. Seek the kindness and generosity of others to help you get back on your feet. There are those willing to help you in your time of need.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F6-of-pentacles.jpg?alt=media&token=2219e474-d391-44d5-bea1-475ab13c4f7b"
        },
        {
            "id": 71,
            "title": "SEVEN of PENTACLES",
            "answer": "YES",
            "description": "Many times people work extremely hard without ever seeming to get ahead. That can tire and frustrate you when your efforts don’t pan out as expected. The 7 of Pentacles shows up when someone has worked hard. Success can and should follow.<br/><br/>You may have lost your faith – in a religion or in yourself. But by giving up now, you will reinforce that you are indeed a quitter. Stick with it and make it happen for yourself. You have all the skills you need to persevere.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F7-of-pentacles.jpg?alt=media&token=55502ff0-e282-4efa-9163-785310760f57"
        },
        {
            "id": 72,
            "title": "EIGHT of PENTACLES",
            "answer": "YES",
            "description": "Are you still an apprentice, or have you mastered your craft? You might be simply working by rote, but the 8 of Pentacles symbolizes a quick mind leaping ahead to what can be conquered next.<br/><br/>In today’s world, it is not enough to do just one thing well. To really get ahead, you must expand your skill set. Setting lofty goals for yourself is an effective way to continue to advance. You may even decide to change your career later in life once you have mastered your present duties.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F8-of-pentacles.jpg?alt=media&token=8c00f93b-03fc-46a8-b7d8-03caf27543a4"
        },
        {
            "id": 73,
            "title": "NINE of PENTACLES",
            "answer": "YES",
            "description": "The 9 of Pentacles is symbolic of a wealthy, well-grounded person. They were not born with a silver spoon in their mouth and earned what they have through hard work, dedication, and strategic decisions.<br/><br/>Your days of want and poverty are far behind now because of your industry and resourcefulness. When this card appears, it asks you whether you have put in the time and effort necessary to earn a good living. If the answer is no, you still have time because this card is quite positive. So, get to work!",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F9-of-pentacles.jpg?alt=media&token=25b358d9-4fc7-4d27-8d30-c54588c496a7"
        },
        {
            "id": 74,
            "title": "TEN of PENTACLES",
            "answer": "YES",
            "description": "Known as the family values card, the 10 of Pentacles is the card of legacies and traditions. Include your extended family when you have a good harvest and more than you can personally use.<br/><br/>It is likely that some sort of family business is at the root of the matter for the querent. This can be an actual brick-and-mortar enterprise or even an inheritance from a late patriarch or matriarch. You are encouraged to open your heart and your home to your family.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2F10-of-pentacles.jpg?alt=media&token=12975e16-e550-4c30-9904-070a164dd3c0"
        },
        {
            "id": 75,
            "title": "PAGE of PENTACLES",
            "answer": "YES",
            "description": "The Page of Pentacles could be a young scholar in awe of money, not for its inherent value but for the value of what it can offer. You can access sources for school tuition or funds available to forge another path. This is the card of the first-generation college student who seeks first to make the family proud and then to better their own life.<br/><br/>You have what it takes to pull it off. Can you deliver it as well?",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fpage-of-pentacles.jpg?alt=media&token=1d932afc-0d29-4f86-8069-50e966971990"
        },
        {
            "id": 76,
            "title": "KNIGHT of PENTACLES",
            "answer": "YES",
            "description": "Of all four Knights in the tarot deck, this is the only one who is not in the throes of some major action. While the Knight of Pentacles is a plodder, don’t doubt their ability to succeed at this steady pace.<br/><br/>There are times when rushing in like a house afire is appropriate. But this is not that time. Proceed at a steady pace and do not be deterred by others’ distractions. That is just extraneous noise. Put your imaginary blinders on and your AirPods in and remain focused on the goals you set for yourself.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fknight-of-pentacles.jpg?alt=media&token=f6910ea1-2d88-4eb3-8212-73b86a8550d7"
        },
        {
            "id": 77,
            "title": "QUEEN of PENTACLES",
            "answer": "YES",
            "description": "This Queen symbolizes mother-energy and nurturing. The Queen of Pentacles is savvy with money and brings great power to the table. In a family unit, the Queen represents stability and security in the home.<br/><br/>Woe to any slacker who shrugs off their responsibilities at home or at work. The Queen first gently nudges, then give a firm boot to anyone who refuses to work or pull their own load.<br/><br/>There is a sensual, earthy side to this card that comes from something far more primal than love. In a love reading, revel in the lust and pleasure the Queen of Pentacles can symbolize.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fqueen-of-pentacles.jpg?alt=media&token=e9643934-cb2d-4699-a2f8-b315e933a2fa"
        },
        {
            "id": 78,
            "title": "KING of PENTACLES",
            "answer": "YES",
            "description": "This card is one of financial stability and great contentment. It represents someone who has all they could ever want and yet remains humble. Despite the riches they have accrued and accumulated through hard work and efforts, they have found a way to stay grounded.<br/><br/>You, too, can be like the King of Pentacles. Keep your confidence high but tempered by humility. You have also put in the time and labor, so don’t give up until it is time to reap your own financial harvest. Remember to stay grounded by giving back and you shall never suffer from any lack.",
            "image": "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2Fking-of-pentacles.jpg?alt=media&token=09149f22-c109-4b29-b85d-f89e099225ff"
        }
    ]
}